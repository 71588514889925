
import i18n from "../locales";
import { BotAction } from "../types";
import Bot from "./bot";
import { isWorkingHours } from "./contact-hours";
import { getAnswerContent, getHelpCategories, getHelpTopics } from "./contentful";


const selectHelpCategoryMessage = i18n.t("bot.selectHelpCategoryMessage")
const selectHelpTopicMessage = i18n.t("bot.selectHelpTopicMessage")
const didThisAnswerYourQuestionMessage = i18n.t("bot.didThisAnswerYourQuestionMessage")
const byeAndGladItWasHelpful = i18n.t("bot.byeAndGladItWasHelpful")
const ihaveAnotherQuestionReply = i18n.t("bot.ihaveAnotherQuestionReply")
const unknownAction = i18n.t("bot.unknownAction")
const noSubtopic = i18n.t("bot.noSubTopics")
const connectToSupportTeam = i18n.t("bot.connectToSupportTeam")
const doYouWantToCallSomeone = i18n.t("bot.doYouWantToCallSomeone")

async function loadAndReplyHelpCategory(bot: Bot, message: string) {
    const actions = await getHelpCategories();
    bot.reply(message, actions);

}
async function greetingMessageSent(bot: Bot) {
    await loadAndReplyHelpCategory(bot, selectHelpCategoryMessage)
}

async function sendAnswerContent(bot: Bot, topicId: string) {
    const messages = await getAnswerContent(topicId);
    for (let message of messages) {
        bot.reply(message)
    }
    bot.reply(didThisAnswerYourQuestionMessage, [
        {
            label: i18n.t("button.yes"),
            value: "Yes",
            type: "issueResolvedAnswer",
        },
        {
            label: i18n.t("button.no"),
            value: "No",
            type: "issueResolvedAnswer",
        },
    ]);
    return messages
}

function issueResolvedAnswerNo(bot: Bot) {
    bot.reply(doYouWantToCallSomeone, [
        {
            label: i18n.t("button.yes"),
            value: "Yes",
            type: "wantToConnectToAgent",
        },
        {
            label: i18n.t("button.no"),
            value: "No",
            type: "dontWantToConnectToAgent",
        },
    ])
}

function restartChat(bot: Bot) {
    return loadAndReplyHelpCategory(bot, ihaveAnotherQuestionReply)
}

async function issueResolvedAnswer(bot: Bot, answer: string, message: string = byeAndGladItWasHelpful) {
    if (answer === "Yes") {
        const actions: BotAction[] = [
            {
                label: i18n.t("bot.closeChat"),
                value: "closeChat",
                type: "needMoreHelpAnswer",
            },
            {
                label: i18n.t("bot.haveAnotherQuestion"),
                value: "restartChat",
                type: "needMoreHelpAnswer",
            }
        ]
        bot.reply(message, actions)
    } else if (answer === "No") {
        issueResolvedAnswerNo(bot)
    }
}

async function needMoreHelpAnswer(bot: Bot, answer: string) {
    if (answer === "closeChat") {
        bot.close()
    } else if (answer === "restartChat") {
        restartChat(bot)
    }
}

async function dontWantToConnectToAgent(bot: Bot, answer: string) {
    issueResolvedAnswer(bot, 'Yes', i18n.t("bot.byeAndThankYouForContactUs"))
}

async function wantToConnectToAgent(bot: Bot, answer: string) {
    const workingHours = isWorkingHours()
    const zendeskLink = "https://uberprocanada.zendesk.com/hc/en-us/requests/new?ticket_form_id=20104205559949";
    if (workingHours) {
        const actions: BotAction[] = [
            {
                label: i18n.t("bot.callSupportButton"),
                value: "",
                type: "callSupport"
            }
        ]
        bot.replyHtmlMessage(i18n.t("bot.callSupport"), actions)
        setTimeout(() => {
            bot.callAgent()
        }, 2000)

    } else {
        const actions: BotAction[] = [
            {
                label: i18n.t("bot.supportCta"),
                value: zendeskLink,
                type: "redirectToExternalLink"
            },
            {
                label: i18n.t("bot.closeChat"),
                value: "closeChat",
                type: "needMoreHelpAnswer",
            },
            {
                label: i18n.t("bot.haveAnotherQuestion"),
                value: "restartChat",
                type: "needMoreHelpAnswer",
            }
        ]
        bot.replyHtmlMessage(i18n.t("bot.supportAgentNotAvailable", {
            "supportLink": zendeskLink
        }), actions)
    }

}

async function unknowAction(bot: Bot) {
    bot.reply(unknownAction)
}

async function noSubtopcActions(bot: Bot) {
    return issueResolvedAnswer(bot, 'Yes', noSubtopic)
}

function redirectToLink(bot: Bot, link: string) {
    window.location.href = link
    return;
}

function callSupport(bot: Bot) {
    bot.callAgent()
    issueResolvedAnswer(bot, 'Yes', i18n.t("bot.byeAndThankYouForContactUs"))
    return;
}

export default async function processAction(bot: Bot, action: BotAction) {
    switch (action.type) {
        case "greetingMessageSent":
            return greetingMessageSent(bot)
        case "selectHelpCategory":
            const actions = await getHelpTopics(action.value);
            if (!actions?.length) {
                return noSubtopcActions(bot);
            }
            return bot.reply(selectHelpTopicMessage, actions);
        case "selectHelpTopic":
            return sendAnswerContent(bot, action.value)
        case "issueResolvedAnswer":
            return issueResolvedAnswer(bot, action.value)
        case "needMoreHelpAnswer":
            return needMoreHelpAnswer(bot, action.value)
        case "dontWantToConnectToAgent":
            return dontWantToConnectToAgent(bot, action.value)
        case "wantToConnectToAgent":
            return wantToConnectToAgent(bot, action.value)
        case "redirectToExternalLink":
            return redirectToLink(bot, action.value)
        case "callSupport":
            return callSupport(bot)
        default:
            console.error("Unknown action type", action)
            return unknowAction(bot)
        // throw new Error("Unknown action type")
    }
}