import { useSessionStorageState } from "ahooks";

const UserSessionKey = "userSession";

type UserSession = {
  userId: number,
  ting: string,
  firstName: string,
  lastName: string,
}
export default function useUserSession() {
  const [userSession, setUserSession] = useSessionStorageState<UserSession | null>(UserSessionKey, { defaultValue: null });
  return { userSession, setUserSession };
}