import { Button, Typography } from '@mui/material';

import { closeChat } from '../../../../services/mobile-comunications';
import {
  ActionContainer,
  ContentBody,
  ContentContainer,
  ContentHeader,
} from './LayoutComponents';

export default function ChatInactiveClose(): JSX.Element {
  const onGotitHandler = () => {
    closeChat()
  };
  return (
    <ContentContainer>
      <ContentHeader title="Chat closed" />
      <ContentBody>
        <Typography variant="body1" component="p">
          Looks like you stepped away. If you still have a question, please start a new chat.
        </Typography>
        <ActionContainer>
          <Button variant="contained" fullWidth onClick={onGotitHandler}>
            Got it
          </Button>
        </ActionContainer>
      </ContentBody>
    </ContentContainer>
  );
}
