import { useEffect, useState } from "react";
import { ChatMessage } from "../../../services/types";
import { Bot } from "../../../services";

type UseBotOptions = {
  firstName: string,
  chatHistory: ChatMessage[]
}
export default function useChatBot(bot: Bot, option: UseBotOptions,  onMessageCallback: Function): [boolean, number] {
  const [botReady, setBotRedy] = useState(false);
  const [startingIndex, setStartingIndex] = useState<number>(-1);
  const { firstName } = option;
  useEffect(() => {
    const messageSubscription = bot.messageStream$
      .subscribe((message: ChatMessage) => {
        onMessageCallback(message);
      });

    bot.setFirstName(firstName);
    if (option.chatHistory.length > 0) {
      bot.setChatHistory(option.chatHistory);
      setStartingIndex(bot.historyIndex);
    }else{
      bot.boot();
    }
    setBotRedy(true);
    return () => {
      messageSubscription.unsubscribe();
      bot.destroy()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bot, firstName])
  return [botReady, startingIndex]
}