import { v4 as uuidv4 } from 'uuid';
import processAction from './process-message-actions';
import { ChatMessage } from './types';
import { BotAction } from '../types';
import { Subject } from 'rxjs';
import i18n from '../locales';
import { callSupport } from './mobile-comunications';

export default class Bot {
  messageWindow: HTMLElement;
  private events = new Map<string, Function[]>();
  chatHistory: ChatMessage[] = [];
  messageStream$ = new Subject<ChatMessage>();
  firstName: string|null = null;
  historyIndex = -1;
  private booted = false;
  constructor() {
    this.messageWindow = document.getElementById('botkit_messagewindow') as HTMLElement;
    this.on('action', (action: BotAction) => {
      processAction(this, action)
    });
  }
  setFirstName(firstName: string) {
    this.firstName = firstName;
  }
  boot() {
    if (this.booted) return;
    const grettingMessage = i18n.t('bot.greetingMessage', { firstName: this.firstName })
    this.trigger('connect', {});
    this.greeting(grettingMessage);
    this.booted = true;
  }

  close() {
    this.trigger('close', {});
  }

  callAgent() {
    callSupport()
  }

  clear() {
    this.chatHistory = [];
    this.trigger('clear', {});
  }

  setChatHistory(chatHistory: ChatMessage[]) {
    this.chatHistory = chatHistory;
    this.historyIndex = chatHistory.length - 1;
  }

  greeting(message: string) {
    if (this.chatHistory.length > 0) return;
    this.addMessage({
      id: uuidv4(),
      sender: "bot",
      message
    });
    this.trigger('action', {
      type: "greetingMessageSent",
      label: "Greeting Message Sent",
      value: "Greeting Message Sent",
    });
  }

  sendMessage(message: string) {
    this.addMessage({
      id: uuidv4(),
      sender: "user",
      message
    });
  }

  sendAction(action: BotAction) {
    this.addMessage({
      id: uuidv4(),
      sender: "user",
      message: action.label
    });
    this.trigger('action', action);
  }

  reply(message: string, actions?: BotAction[]) {
    this.addMessage({
      id: uuidv4(),
      sender: "bot",
      message,
      actions
    });
  }

  replyHtmlMessage(message: string, actions?: BotAction[]) {
    this.addMessage({
      id: uuidv4(),
      sender: "bot",
      message,
      actions,
      isHtml: true
    });
  }

  on(eventName: string, callback: (detail: any) => void) {
    if (!this.events.has(eventName)) {
      this.events.set(eventName, []);
    }
    this.events.get(eventName)?.push(callback);
    this.messageWindow.addEventListener(eventName, (e: Event) => {
      callback((e as CustomEvent).detail);
    });
  }

  off(eventName: string, callback: (detail: any) => void) {
    this.messageWindow.removeEventListener(eventName, callback);
  }

  destroy() {
    for (const [eventName, callbacks] of this.events.entries()) {
      for (const callback of callbacks) {
        this.messageWindow.removeEventListener(eventName, callback as EventListener);
      }
    }
    this.trigger('destroy', {});
  }

  private trigger(eventName: string, detail: any) {
    const event = new CustomEvent(eventName, { detail });
    this.messageWindow.dispatchEvent(event);
  }

  private addMessage(message: ChatMessage) {
    this.chatHistory = [...this.chatHistory, message];
    this.messageStream$.next(message);
  }
}