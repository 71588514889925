import { Navigate, createBrowserRouter } from "react-router-dom";
import Chat from "./pages/chat/chat";
import StartChatSession from "./pages/StartChatSession";

const router = createBrowserRouter([
  {
    path: "/",
    element: <StartChatSession />,
  },
  {
    path: "/chat",
    element: <Chat />,
  },
]);

export default router;