import { Button, styled } from '@mui/material';

import {
  ActionContainer,
  ContentBody,
  ContentContainer,
} from './LayoutComponents';
import { closeModalBox } from './useModalBox';
import { callSupport } from '../../../../services/mobile-comunications';

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '17px',
  color: '#0A84FF',
  borderRadius: '14px',
  backgroundColor: 'rgba(37, 37, 37, 0.82)',

  "&:hover": {
    backgroundColor: 'rgba(37, 37, 37, 0.82)',
  },
  "&:focus": {
    backgroundColor: 'rgba(37, 37, 37, 0.82)',
  },
  "@media (hover: none)": {
    "&:hover": {
      backgroundColor: 'rgba(37, 37, 37, 0.82)',
    },
  },
}));
export default function CallAgent(): JSX.Element {
  const callSupportClicked = () => {
    callSupport()
  }
  return (
    <ContentContainer>
      <ContentBody>
        <ActionContainer>
          <StyledButton variant="contained"
          fullWidth onClick={callSupportClicked}>
            Call +1(548)-490-8237
          </StyledButton>
          <StyledButton variant="contained"
          fullWidth onClick={closeModalBox}>
            Cancel
          </StyledButton>
        </ActionContainer>
      </ContentBody>
    </ContentContainer>
  );
}
