import { Box, Chip, ListItemAvatar, styled } from '@mui/material';
import React from 'react';

export const StyledListItemAvatar = styled(ListItemAvatar)(({ theme }) => ({
  minWidth: '40px',
}));

export const StyledContent = styled(Box)(({ theme }) => ({
  borderRadius: '12px',
  flexGrow: 1,
  border: '2px solid rgba(0, 0, 0, 0.08)',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1, 2, 1, 2),
  display: 'flex',
  flexDirection: 'column',
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  color: '#000',
  fontSize: '14px',
  fontWeight: 500,
  '& .MuiChip-label': {
    display: 'block',
    whiteSpace: 'normal',
    padding: '8px 10px'
  },
  height: 'initial',
}));

type MessageChipActionProps = React.ComponentProps<typeof Chip>;
export const MessageChipAction: React.FC<MessageChipActionProps> = (props) => {
  return <Box sx={{mb: 0.5}}>
    <StyledChip {...props} />
  </Box>
}
