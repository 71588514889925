import React, { useState, useEffect } from 'react';

const DefaultTimeout = 15 * 60 * 1000;

interface IdleTimerProps {
  timeout?: number;
  onIdle: () => void;
}
const useIdleTimer = (props: IdleTimerProps) => {
  const {timeout = DefaultTimeout, onIdle} = props;
  const [lastActive, setLastActive] = useState(Date.now());

  const handleActivity = () => {
    setLastActive(Date.now());
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleActivity);
    document.addEventListener('keydown', handleActivity);
    document.addEventListener('scroll', handleActivity);

    const interval = setInterval(() => {
      if (Date.now() - lastActive > timeout) {
        onIdle()
        console.log('User is idle');
      }
    }, 1000);

    return () => {
      document.removeEventListener('mousemove', handleActivity);
      document.removeEventListener('keydown', handleActivity);
      document.removeEventListener('scroll', handleActivity);
      clearInterval(interval);
    };
  }, [lastActive, onIdle, timeout]);

  return lastActive;
};

export default useIdleTimer;