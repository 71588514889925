import axios, { AxiosError } from "axios";
import { ChannelParams, PayfareResponse, UpgradeBishopTokenParams } from "../../types";
import { buildGeneralAxiosConfig, getBishopToken, storeToken } from "./common";
import { CHANNEL, COUNTRY_CODE } from "./constants";
import { BishopTokenResponseData, BishopUserInfoData } from "./types";


export function getParamsFromUrl(): UpgradeBishopTokenParams {
  const urlParams = new URLSearchParams(window.location.search);
  const oneTimeToken = (urlParams.get("ott") || urlParams.get("bibson")) as string;
  const ting = urlParams.get("ting") as string;
  const userId = Number(urlParams.get("gibby"));
  const langCode = urlParams.get("lang_code") || "en-ca";
  return {
    oneTimeToken,
    ting,
    userId,
    lang_code: langCode,
    channel: CHANNEL,
    country_code: COUNTRY_CODE,
  };
}

function getUserSessionFromStorage() {
  const userSessionData = getBishopToken();
  return userSessionData;
}

export async function getOrUpgradeUserSession(params: UpgradeBishopTokenParams) {
  const {
    userId,
    ting,
    oneTimeToken,
    lang_code,
  } = params;

  let userSessionData;
  if (!oneTimeToken) {
    userSessionData = getUserSessionFromStorage();
  }
  if (userSessionData) return userSessionData.session.bibson;

  const endPoint = `/v6/sessions/${userId}/upgrade`;

  const upgradeSessionParams: ChannelParams = {
    channel: CHANNEL,
    country_code: COUNTRY_CODE,
    lang_code
  }

  const headers = {
    "Content-Type": "application/json",
    "x-bishop": oneTimeToken,
  }
  const bibson = await axios
    .post<PayfareResponse<BishopTokenResponseData>>(endPoint, upgradeSessionParams, { headers, })
    .then((res) => res.data.data.bibson);

  storeToken({
    env: {
      userId: Number(userId),
      ting,
      lang_code,
      channel: CHANNEL,
      country_code: COUNTRY_CODE,
    },
    session: { bibson },
  })

  return bibson;
}


export async function getUserInfo(): Promise<BishopUserInfoData> {
  const { env: userEnv } = getBishopToken()
  const { userId } = userEnv
  const endpoint = `/v6/users/${userId}`
  const config = buildGeneralAxiosConfig()
  return await axios.get<PayfareResponse<BishopUserInfoData>>(endpoint, config)
    .then((res) => {
      return res.data.data
    })
    .catch((err: AxiosError) => {
      throw err
    })
}

export function connectSupportAgent() {

}