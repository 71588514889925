import React, { useEffect } from 'react';

import ChatClose from './ChatClose';
import ChatCloseConfirmation from './ChatCloseConfirmation';
import LeaveChat from './LeaveChat';
import CallAgent from './CallAgent';
import ConfirmationDrawer from '../../../../common/ConfirmationDrawer/ConfirmationDrawer';
import {
  ModalBoxName,
  ModalBoxOpeningSubject,
  closeModalBox,
} from './useModalBox';
import ChatInactiveClose from './ChatInactiveClose';

const TransparentModals = new Set(['call-agent']);

function ContentFactory(modalName: ModalBoxName): React.ReactNode | null {
  switch (modalName) {
    case 'leave-chat-confirmation':
      return <ChatCloseConfirmation />;
    case 'leave-chat':
      return <LeaveChat />;
    case 'chat-close':
      return <ChatClose />;
    case 'call-agent':
      return <CallAgent />;
    case 'close-inactive-chat':
      return <ChatInactiveClose />;
    default:
      return null;
  }
}

export default function BottomModal() {
  const [modalName, setModalName] = React.useState<ModalBoxName>(null);

  useEffect(() => {
    const subscription = ModalBoxOpeningSubject.asObservable().subscribe(
      (name) => {
        setModalName(name);
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const onCloseModal = () => {
    closeModalBox();
  };

  if (modalName === null) {
    return null;
  }

  const content = ContentFactory(modalName);
  return (
    <ConfirmationDrawer
      transparent={TransparentModals.has(modalName)}
      open
      onClose={onCloseModal}
      onOpen={() => console.info('open')}
      modalBoxContent={content}
    />
  );
}
