import { useEffect } from "react";
import { Bot } from "../../../services";

export default function useChatOnClose(bot: Bot, callBack: Function) {

  useEffect(() => {
    const onCloseHandler = (payload: any) => {
      callBack(payload)
    }
    bot.on('close', onCloseHandler)
    return () => {
      bot.off('close', onCloseHandler)
    }
  }, [bot, callBack])
  return bot
}
