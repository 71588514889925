import { Box } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type ChatContainerProps = {};
const ChatContainer: FC<PropsWithChildren<ChatContainerProps>> = ({
  children,
}) => {
  return <Box sx={{ flexGrow: 1, p: 2, padding: '0 16px 0 16px' }}>{children}</Box>;
};

export default ChatContainer;
