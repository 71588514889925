import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const ContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const HeaderSection = styled(Box)(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  padding: theme.spacing(0, 0, 1, 0),
  borderBottom: '1px solid #DDDDDD',
}));

export const ContentHeader = (props: { title: string }) => {
  return (
    <HeaderSection>
      <Typography variant="h5">{props.title}</Typography>
    </HeaderSection>
  );
};

export const ContentBody = (props: { children: React.ReactNode }) => {
  return (
    <Box sx={{ width: '100%', p: 2 }}>
      {props.children}
    </Box>
  );
}

export const ActionContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2,0, 0,0),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: theme.spacing(1),
}));

