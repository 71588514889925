import { Box, styled } from '@mui/material';

import i18n from '../../../../locales';
import { useSessionStorageState } from 'ahooks';
import { useTranslation } from "react-i18next";

function formatTime(time: Date): string {
  let hours = time.getHours();
  const fullMinutes = time.getMinutes();
  hours = hours % 12;
  hours = hours ? hours : 12;
  
  const minutes = fullMinutes < 10 ? `0${fullMinutes}` : fullMinutes;
  const ampm = hours >= 12 ? 'PM' : 'AM';
  return `${hours}:${minutes} ${ampm}`;
}

const ChattingWithBotDiv = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
}));

export default function ChatStartInfo() {
  const startedChatAt = new Date();
  const time = formatTime(startedChatAt);
  const [startedAt] = useSessionStorageState<string>('chat-started-at', {defaultValue: time});
  const {t} = useTranslation()

  return (
    <Box sx={{ textAlign: 'center', mb: 3 }}>
      <div>{i18n.t('bot.chatStartedAt', { time: startedAt })}</div>
    </Box>
  );
}
