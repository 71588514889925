import { useEffect } from "react"
import { AppHeaderEvent } from "./types"
import { ChatAppHeaderAction } from "./useChatAppBar"
import { debounceTime } from "rxjs"

export type CalbackFunction = (action: AppHeaderEvent) => void
type HookOptions = {
  debounceTime?: number
}
export default function useAppBarAction(callback: CalbackFunction, options: HookOptions = { debounceTime: 500 }): void {
  useEffect(() => {
    const subscrition = ChatAppHeaderAction
      .asObservable()
      .pipe(
        debounceTime(500)
      )
      .subscribe(callback)
    return () => { subscrition.unsubscribe() }
  }, [])
}