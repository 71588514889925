import { BehaviorSubject } from "rxjs";

export type ModalBoxName =
  | 'leave-chat-confirmation'
  | 'leave-chat'
  | 'chat-close'
  | 'call-agent'
  | 'close-inactive-chat'
  | null;

export const ModalBoxOpeningSubject = new BehaviorSubject<ModalBoxName>(null);

export function openModalBox(name: ModalBoxName) {
  ModalBoxOpeningSubject.next(name);
}

export function closeModalBox() {
  ModalBoxOpeningSubject.next(null);
}