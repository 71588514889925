import { FC, PropsWithChildren } from 'react';
import { LayoutContextProps } from '../types';
import LayoutContext from './LayoutContext';

const LayoutContextProvider: FC<
  PropsWithChildren<Partial<LayoutContextProps>>
> = (props) => {
  const { children, loadingElement } = props;
  return (
    <LayoutContext.Provider value={{ loadingElement }}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutContextProvider;
