import { useContext } from "react";
import { TypingContolContext, TypingControlContextType } from "./TypingContolProvider";

export default function useTypingControl(){
  const context = useContext(TypingContolContext);
  if (!context) {
    throw new Error(
      "TypingContolContext must be used within a TypingContolProvider"
    );
  }
  return context as TypingControlContextType;
}