import { FC } from 'react';
import { UserSessionContext } from '../../common/OneTimeTokenWrapper';
import {
  getOrUpgradeUserSession,
  getParamsFromUrl,
  getUserInfo,
} from '../../services/bishop/service';
import useLayout from '../../layouts/chat/context/useLayout';
import { useRequest } from 'ahooks';
import { Navigate } from 'react-router-dom';
import useUserSession from '../../common/useUserSession/useUserSession';

const StartChatSession: FC = () => {
  const params = getParamsFromUrl();
  const {setUserSession} = useUserSession();

  const upgradeBishopToken = async (): Promise<UserSessionContext> => {
    await getOrUpgradeUserSession(params);
    const userInfo = await getUserInfo();
    const userSessionData = {
      userId: userInfo.id,
      ting: userInfo.ting,
      firstName: userInfo.first_name,
      lastName: userInfo.last_name,
    };
    setUserSession(userSessionData);
    return userSessionData;
  };
  const { loadingElement } = useLayout();

  const { loading, error, data } = useRequest(upgradeBishopToken, {
    refreshDeps: [params.oneTimeToken],
    debounceWait: 500
  });

  if (error) return <div>Something went wrong</div>;
  if (loading || !data) return <>{loadingElement}</>;

  return  <Navigate to={{
    pathname: '/chat',
    search: ""
  }} replace={true}  />
};

export default StartChatSession;