import React, { FC, PropsWithChildren } from 'react';
import { Subject } from 'rxjs';

export type TypingControlContextType = {
  finishedMessageRendering: (index: number) => void;
};
export const TypingContolContext =
  React.createContext<TypingControlContextType | null>(null);

type TypingContolProps = {
  typingStream: Subject<number>;
};
const TypingContolProvider: FC<PropsWithChildren<TypingContolProps>> = ({
  typingStream,
  children,
}) => {
  const finishedMessageRendering = (index: number) => {
    typingStream.next(index);
  };
  const value = { finishedMessageRendering };

  return (
    <TypingContolContext.Provider value={value}>
      {children}
    </TypingContolContext.Provider>
  );
};
export default TypingContolProvider;
