import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import BuildTheme from './theme/build-theme';
import React, { Suspense } from 'react';
import ChatAppBar from '../../common/ChatAppBar/ChatAppBar';
import Loadding from './components/Loading';
import LayoutContextProvider from './context/LayoutContextProvider';
import { I18nextProvider } from 'react-i18next';
import i18n from '../../locales';

type LayoutProps = React.PropsWithChildren<{}>;
const Layout: React.FC<LayoutProps> = ({ children }) => {
  const theme = BuildTheme();
  return (
    <I18nextProvider i18n={i18n} defaultNS={'translation'}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LayoutContextProvider loadingElement={<Loadding />}>
          <Box flexGrow={1}>
            <Suspense fallback={<Loadding />}>{children}</Suspense>
          </Box>
        </LayoutContextProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
};

export default Layout;
