import { Button, Typography } from '@mui/material';

import { closeChat } from '../../../../services/mobile-comunications';
import {
  ActionContainer,
  ContentBody,
  ContentContainer,
  ContentHeader,
} from './LayoutComponents';
import { closeModalBox } from './useModalBox';

export default function LeaveChat(): JSX.Element {
  const onClickLeave = () => {
    closeChat();
  };
  return (
    <ContentContainer>
      <ContentHeader title="Leave chat" />
      <ContentBody>
        <Typography variant="body1">
          Looks like you're leaving the chat. You may lose the conversation if you leave.
        </Typography>
        <ActionContainer>
          <Button variant="contained" fullWidth onClick={onClickLeave}>
            Leave
          </Button>
          <Button variant="text" fullWidth onClick={closeModalBox}>
            Cancel
          </Button>
        </ActionContainer>
      </ContentBody>
    </ContentContainer>
  );
}
