import { FC } from "react";
import { Box, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const Wrapper = styled(Box)(({ theme }) => ({
  height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - 100px)`,
  width: '100vw',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  "@media (max-width: 0)": {
    "@media (orientation: landscape)": {
      height: "calc(100vh - 48px - 30px)",
    },
  },
  "@media (max-width: 600px)": {
    height: "calc(100vh - 64px - 100px)",
  },
}));

const Loadding: FC = () => {
  const {t} = useTranslation()
  return <Wrapper>
    <Typography variant="body1" sx={{ fontWeight: 400, mb: 2, textAlign: 'center', paddingBottom: '0' }}>
      {t("loading.Just a second, we are starting the chat.")}
    </Typography>
  </Wrapper>
}

export default Loadding