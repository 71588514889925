import { Avatar, Box, Typography, styled } from '@mui/material';

import userAvatar from '../../assets/images/user-avatar.png';
import { StyledListItemAvatar, StyledContent } from './Common';
import { MessagePropsBase } from './types';
import { useMount } from 'ahooks';
import useTypingControl from './useTypingControl';

const MessageContent = styled(Typography)(() => ({
  color: '#FFF',
  textAlign: 'right',
}));

const MessageContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'row-reverse',
}));

type UserMessageProps = MessagePropsBase & {
  name?: string;
};

const UserMessage: React.FC<UserMessageProps> = ({ messageIndex, message, name }) => {
  const { finishedMessageRendering } = useTypingControl();

  useMount(() => {
    finishedMessageRendering(messageIndex);
  });

  return (
    <MessageContainer>
      <StyledListItemAvatar>
        <Avatar alt={name} src={userAvatar} sx={{ height: '34px', width: '34px', }} />
      </StyledListItemAvatar>
      <Box sx={{ pr: 1, pl: 5 }}>
        <StyledContent sx={{ background: '#000' }}>
          <MessageContent variant="body1">{message}</MessageContent>
        </StyledContent>
      </Box>
    </MessageContainer>
  );
};

export default UserMessage;
