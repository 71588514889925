import Layout from './layouts/chat/layout';
import { RouterProvider } from 'react-router-dom';
import router from './Router';


function App() {
  return (
    <Layout>
       <RouterProvider router={router} />
    </Layout>
  );
}

export default App;
