import { createTheme } from '@mui/material/styles';

export default function BuildTheme(){
  const theme = createTheme({
    palette: {
      primary: {
        main: '#000000',
        contrastText: '#ffffff',
      },
      text:{
        primary: '#000000',
        secondary: '#ffffff',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: "18px",
            textTransform: "none",
            padding: "8px 16px",
          },
          contained: {
            borderRadius: "8px",
          }
        }
      }
    },
    typography: {
      fontFamily: [
        "UberMoveText",
        'Roboto',
        'sans-serif',
      ].join(','),
      h1: {
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
      },
      // Confirmation Box title
      h5: {
        fontWeight: 500,
        fontSize: "18px",
      },
      // For chat messages
      body1: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
        fontStyle: "normal",
      }
    }
  });
  return theme;
}