import { Button, Typography } from '@mui/material';

import { closeChat } from '../../../../services/mobile-comunications';
import {
  ActionContainer,
  ContentBody,
  ContentContainer,
  ContentHeader,
} from './LayoutComponents';

export default function ChatClose(): JSX.Element {
  const onGotitHandler = () => {
    closeChat()
  };
  return (
    <ContentContainer>
      <ContentHeader title="Chat closed" />
      <ContentBody>
        <Typography variant="body1" component="p">
          Sorry, we closed the chat because we didn't get a response for more
          than x minutes.
        </Typography>
        <Typography variant="body1" component="p" sx={{mt: 1}}>
          If you still have a question, please re-start the chat.
        </Typography>
        <ActionContainer>
          <Button variant="contained" fullWidth onClick={onGotitHandler}>
            Got it
          </Button>
        </ActionContainer>
      </ContentBody>
    </ContentContainer>
  );
}
