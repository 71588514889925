import { MobileMessage, MobileMessageCode } from "../types"


// Messages To Mobile
export const MessageToMobile: Record<string, MobileMessage> = {}

MessageToMobile.chatCloseMessage = {
  code: MobileMessageCode.chatClose,
  description: "close chat",
  action: "chat-close",
}

MessageToMobile.chatLoading = {
  code: MobileMessageCode.chatLoading,
  description: "chat loadding",
  action: "chat-loading",
}

MessageToMobile.openZendeskSupport = {
  code: MobileMessageCode.openZendeskSupport,
  description: "open zendesk support link",
  action: "open-zendesk-support",
}

MessageToMobile.callSupportMessage = {
  code: MobileMessageCode.callSupport,
  description: "call support",
  action: "call-support",
}

export function sendMessageToMobie(message: MobileMessage) {
  console.log(JSON.stringify(message))
}

export function openNewZendeskTicket(zendeskUrl: string) {
  sendMessageToMobie({ ...MessageToMobile.openZendeskSupport, data: zendeskUrl })
}

export function closeChat() {
  sendMessageToMobie(MessageToMobile.chatCloseMessage)
}

export function callSupport() {
  sendMessageToMobie({...MessageToMobile.callSupportMessage, data: '+15484908237'})
}