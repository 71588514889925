
import { BehaviorSubject, Subject } from "rxjs";
import { AppHeaderEvent, ChatAppBarHookReturn } from "./types";

export const ChatAppHeaderSubject = new BehaviorSubject<string>("");
export const ChatAppHeaderAction = new Subject<AppHeaderEvent>();

export default function useChatAppBar(): ChatAppBarHookReturn {
  const changeHeader = (header: string) => {
    ChatAppHeaderSubject.next(header)
  }

  const publishAction = (action: AppHeaderEvent) => {
    ChatAppHeaderAction.next(action)
  }

  return {
    changeHeader,
    publishAction,
    ChatAppHeaderAction
  }
}
