import * as contentful from 'contentful'
import { HelpCategoryFieldId, HelpTopicFieldId, UberProContentfulTag } from './constants'
import { BotAction } from '../types'
import { getParamsFromUrl } from './bishop/service';

const client = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID as string,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN as string,
})

const SelectedLanguageStorageKey = 'selectedLanguage';
const { lang_code: langCode } = getParamsFromUrl();

const storedLanguage = sessionStorage.getItem(SelectedLanguageStorageKey);
const language = ((langCode.split('-')[0] || storedLanguage) ?? 'en').toLowerCase();
const contentFulLocale = language === 'en' ? 'en-US' : 'fr-CA';

type DefaultQueryType = contentful.MetadataTagsQueries & contentful.FixedQueryOptions & contentful.FixedPagedOptions & contentful.LocaleOption
const DefaultQuery: DefaultQueryType = {
  limit: 20,
  "metadata.tags.sys.id[in]": [UberProContentfulTag],
  locale: contentFulLocale,
}

type HelpCategorySkeleton = {
  contentTypeId: string
  sys: contentful.EntitySys,
  fields: {
    title: contentful.EntryFieldTypes.Text
    subtitle: contentful.EntryFieldTypes.Text
    icon: contentful.EntryFieldTypes.AssetLink
  }
}

type HelpTopicSkeleton = {
  contentTypeId: string
  fields: {
    title: contentful.EntryFieldTypes.Text
    content: contentful.EntryFieldTypes.Text
    richTextContent: contentful.EntryFieldTypes.RichText
    categories: contentful.EntryFieldTypes.Array<
      contentful.EntryFieldTypes.EntryLink<HelpCategorySkeleton>
    >
  }
}

export async function getHelpCategories(): Promise<BotAction[]> {
  const { items } = await client.getEntries<HelpCategorySkeleton>({
    content_type: HelpCategoryFieldId,
    ...DefaultQuery
  })
  console.log('getHelpCategories', items)
  return items.map(({ fields, sys }) => ({
    label: fields.title,
    value: sys.id,
    type: "selectHelpCategory",
    isChip: true,
  })).sort((a,b) => a.label.localeCompare(b.label)) as BotAction[]
}

export async function getHelpTopics(categoryId: string): Promise<BotAction[]> {
  const query = {
    ...DefaultQuery,
    "fields.categories.sys.id[in]": categoryId
  }

  const { items } = await client.getEntries<HelpTopicSkeleton>({
    content_type: HelpTopicFieldId,
    ...query
  })

  return items.map(({ fields, sys }) => ({
    label: fields.title,
    value: sys.id,
    type: "selectHelpTopic",
    isChip: true,
  }))
}

export async function getAnswerContent(topicId: string): Promise<string[]> {
  const {fields} = await  client.getEntry<HelpTopicSkeleton>(topicId, {
    locale: contentFulLocale,
  })
  console.log('fields.content', fields.content)
  return [fields.content.trim()];
}