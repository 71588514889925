import { getTimezoneOffset } from 'date-fns-tz'

const WorkingTime = {
  FORM: 9,
  TO: 19
}
const AgentTimezone = "America/New_York"
const UserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

export function currentAgentTime(){
  const now = new Date()
  const offset = getTimezoneOffset(AgentTimezone, now)
  const userTimeOffset = getTimezoneOffset(UserTimezone, now)
  const agentTime = new Date(now.getTime() + offset - userTimeOffset)
  return agentTime
}

export function isWorkingHours() {
  const agentTime = currentAgentTime()
  const hour = agentTime.getHours()
  return hour >= WorkingTime.FORM && hour < WorkingTime.TO
}