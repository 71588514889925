import { BISHOP_TOKEN_STORAGE_KEY } from "./constants";
import { ChannelParams, SessionToken, UserParams } from "../../types";


type UserSessionData = {
  env:  UserParams & ChannelParams,
  session: SessionToken
}

export function storeToken(data: UserSessionData) {
  localStorage.setItem(BISHOP_TOKEN_STORAGE_KEY, JSON.stringify(data));
}

export function getBishopToken(): UserSessionData {
  const data = localStorage.getItem(BISHOP_TOKEN_STORAGE_KEY);
  return JSON.parse(data || "{}") as UserSessionData;
}

export function buildGeneralAxiosConfig() {
  const bishopData = getBishopToken();
  return {
    params: {
      ...bishopData.env
    },
    headers: {
      "x-bishop": bishopData?.session?.bibson,
      "Content-Type": "application/json",
    },
  };
}
