import { PropsWithChildren, forwardRef } from 'react';

import { Box } from '@mui/material';

const Scrollbar = forwardRef<HTMLElement, PropsWithChildren<{}>>(
  (props, ref) => {
    const { children } = props;

    return (
      <Box ref={ref} sx={{ overflowX: 'inherit', height: 'calc(100vh - 100px)', padding: '0' }}>
        {children}
      </Box>
    );
  }
);

export default Scrollbar;
