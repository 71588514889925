import { Box } from '@mui/material';

import { AnimatePresence, motion } from 'framer-motion';

import { MessageChipAction } from '../Common';
import { BotAction } from '../../../types';
import { ComponentProps, PropsWithChildren } from 'react';

type MotionDivProps = ComponentProps<typeof motion.div>;
const NonMotionDiv = (props: PropsWithChildren<MotionDivProps>) => {
  return <Box>{props.children}</Box>;
};

type MessageActionContainerProps = {
  show: boolean;
  actions?: Array<BotAction>;
  onClickAction?: (action: BotAction) => void;
  animation?: boolean;
};
const Container = (props: MessageActionContainerProps) => {
  if (!props.actions || props.actions.length === 0) {
    return null;
  }

  if (!props.show) {
    return null;
  }
  const clickHandler = (action: BotAction) => {
    if (props.onClickAction) {
      props.onClickAction(action);
    }
  };

  const MotionComponent = props.animation ? motion.div : NonMotionDiv;

  return (
    <Box sx={{ mt: 1 }}>
      <AnimatePresence>
        {props.actions.map((action, index) => {
          return (
            <MotionComponent
              key={action.value}
              initial={{ opacity: 0, y: -30 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: { duration: 0.1, delay: index * 0.1 },
              }}
            >
              <MessageChipAction
                label={action.label}
                sx={{
                  width: action.isChip ? 'auto' : '100%',
                }}
                onClick={() => clickHandler(action)}
              />
            </MotionComponent>
          );
        })}
      </AnimatePresence>
    </Box>
  );
};

export default Container;
