import { useEffect } from "react"
import { Bot } from "../../../services"

export function useChatOnCallAgent(bot: Bot, callBack: Function) {

  useEffect(() => {
    const onCallAgentHandler = (payload: any) => {
      callBack(payload)
    }
    bot.on('callAgent', onCallAgentHandler)
    return () => {
      bot.off('callAgent', onCallAgentHandler)
    }
  }, [bot, callBack])
  return bot
}