import { AppHeaderEvent } from "../../common/ChatAppBar/types";
import { CalbackFunction as HeaderCallbackAction } from "../../common/ChatAppBar/useAppBarAction";
import { openModalBox } from "./components/bottom-modal-box/useModalBox";


const processHeaderActions: HeaderCallbackAction = (action: AppHeaderEvent) => {
  switch (action.name) {
    case "back":
      openModalBox('leave-chat')
      break;
    default:
      break;
  }
}
export default processHeaderActions