import * as React from 'react';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

const drawerBleeding = 56;

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  width: '100%',
}));

interface ConfrimationDrawerProps {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  modalBoxContent?: React.ReactNode;
  transparent?: boolean;
}

export default function ConfirmationDrawer(
  props: ConfrimationDrawerProps
): JSX.Element {
  if (!props.modalBoxContent) return (<></>);
  const paperProps = props.transparent ? { background: 'transparent', boxShadow: "none" } : { background: '#FFFFFF',  borderRadius: '16px 16px 0 0'}
  return (
    <SwipeableDrawer
      anchor="bottom"
      open={props.open}
      onClose={props.onClose}
      onOpen={props.onOpen}
      swipeAreaWidth={drawerBleeding}
      disableSwipeToOpen
      ModalProps={{
        keepMounted: false,
      }}
      PaperProps={{
        sx: {
          ...paperProps
        },
      }}
    >
      <StyledBox
        sx={{
          height: '100%',
          overflow: 'auto',
        }}
      >
        {props.modalBoxContent}
      </StyledBox>
    </SwipeableDrawer>
  );
}
