import { ChatMessage } from '../../services/types';
import { FC } from 'react';
import BotMessage from './BotMessage';
import UserMessage from './UserMessage';
import { BotAction } from '../../types';

type MessageRenderProps = {
  messageIndex: number;
  message: ChatMessage;
  onClickAction?: (action: BotAction) => void;
  isHtml?: boolean;
  typingAnimation?: boolean;
};

const MessageRender: FC<MessageRenderProps> = ({
  messageIndex,
  message,
  onClickAction,
  typingAnimation=true,
  isHtml = false
}) => {  
  if (message.sender === 'bot') {
    return (
      <BotMessage
        messageIndex={messageIndex}
        onClickAction={onClickAction}
        message={message.message}
        actions={message.actions}
        isHtml={isHtml}
        typingAnimation={typingAnimation}
      />
    );
  } else {
    // TODO: replace name with user name
    return (
      <UserMessage
        message={message.message}
        name="John"
        messageIndex={messageIndex}
      />
    );
  }
};


export default MessageRender;
