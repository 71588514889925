export type BotActionType = "greetingMessageSent" | "selectHelpCategory" | "selectHelpTopic" | "issueResolvedAnswer" |
  "needMoreHelpAnswer" | "restartChat" | "wantToConnectToAgent" | "dontWantToConnectToAgent" | "closeChat" | "redirectToExternalLink" | "callSupport"

export type BotAction = {
  label: string,
  value: string,
  type: BotActionType,
  isChip?: boolean,
}


// Payfare Service
type ErrorData = {
  code: string;
  message: string;
  path: string;
  arguments: Array<object>;
};

export interface PayfareError {
  code: string;
  message: string | null;
  field: string;
  data: {
    errors: Array<ErrorData>;
  };
}

export interface PayfareResponse<T> {
  status: string;
  data: T;
  errors?: PayfareError[];
  message?: string | object;
}

export enum MobileMessageCode {
  chatLoading = 1000,
  chatClose = 4000,
  openZendeskSupport = 5000,
  callSupport = 6000
}

export type MobileActions = 'chat-close' | 'chat-loading' | 'chat-error' | 'open-zendesk-support' | 'call-support'

export interface MobileMessage {
  code: MobileMessageCode
  description: string,
  action: MobileActions
  data?: any,
}

export interface ChannelParams{
  channel: string;
  country_code: string;
  lang_code: string;
}
export interface UserParams{
  userId: number;
  ting: string;
}

export interface SessionToken {
  bibson: string;
}

export interface OneTimeTokenSession{
  oneTimeToken: string;
}

export type UpgradeBishopTokenParams = OneTimeTokenSession & UserParams & ChannelParams;

