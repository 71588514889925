import { Button, Typography } from '@mui/material';

import { closeChat } from '../../../../services/mobile-comunications';
import {
  ActionContainer,
  ContentBody,
  ContentContainer,
  ContentHeader,
} from './LayoutComponents';

export default function ChatCloseConfirmation(): JSX.Element {
  const onClickGotIt = () => {
    closeChat();
  };
  return (
    <ContentContainer>
      <ContentHeader title="Chat closed" />
      <ContentBody>
        <Typography variant="body1">
          Thank you so much for contacting us.
        </Typography>
        <ActionContainer>
          <Button variant="contained" fullWidth onClick={onClickGotIt}>
            Got it
          </Button>
        </ActionContainer>
      </ContentBody>
    </ContentContainer>
  );
}
