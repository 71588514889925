import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './en/en.json';
import fr from './fr/fr.json';
import { getParamsFromUrl } from "../services/bishop/service";
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

const SelectedLanguageStorageKey = 'selectedLanguage';
const resources = {
  en: {
    translation: en
  },
  fr: {
    translation: fr
  }
};

const { lang_code: langCode } = getParamsFromUrl();

const storedLanguage = sessionStorage.getItem(SelectedLanguageStorageKey);
const language = ((langCode.split('-')[0] || storedLanguage) ?? 'en').toLowerCase();

sessionStorage.setItem(SelectedLanguageStorageKey, language)

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;